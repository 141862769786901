'use client';

import { cn } from '@nextui-org/theme';
import React from 'react';

import { caption, paragraph } from '@/theme/typography';
import { humanizeNumber } from '@/utils/number';
import { UserFollowersModal, UserFollowingModal } from '@/modals';

interface UserFollowProps {
  followers: number;
  following: number;
  username: string;
}

const UserFollow: React.FC<UserFollowProps> = ({ followers, following, username }) => {
  const [isFollowersModalOpen, setIsFollowersModalOpen] = React.useState(false);
  const [isFollowingModalOpen, setIsFollowingModalOpen] = React.useState(false);

  return (
    <>
      <div className='item-center flex flex-row gap-6'>
        {[
          { title: 'Followers', value: followers, onClick: () => setIsFollowersModalOpen(true) },
          { title: 'Following', value: following, onClick: () => setIsFollowingModalOpen(true) },
        ].map(({ title, value, onClick }) => (
          <div
            key={title}
            className='flex flex-row items-center gap-2'
            role='button'
            tabIndex={-1}
            onClick={onClick}
            onKeyUp={onClick}
          >
            <span className={cn(paragraph({ weight: 'medium' }))}>{humanizeNumber(value)}</span>
            <span className={cn(caption())}>{title}</span>
          </div>
        ))}
      </div>

      {isFollowersModalOpen && (
        <UserFollowersModal
          isOpen={isFollowersModalOpen}
          username={username}
          onOpenChange={setIsFollowersModalOpen}
        />
      )}
      {isFollowingModalOpen && (
        <UserFollowingModal
          isOpen={isFollowingModalOpen}
          username={username}
          onOpenChange={setIsFollowingModalOpen}
        />
      )}
    </>
  );
};

export default UserFollow;
