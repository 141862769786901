'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import { cn } from '@nextui-org/theme';

import { Tabs } from '@/components';
import { body } from '@/theme/typography';

interface UserProfileTabProps {
  username: string;
  permissions: string[];
}

const UserProfileTab: React.FC<UserProfileTabProps> = ({ username, permissions }) => {
  const pathname = usePathname();

  return (
    <Tabs
      className='p-0'
      classNames={{ tabList: 'p-0', tabContent: `capitalize ${cn(body())}` }}
      items={[
        {
          key: 'articles',
          title: 'Articles',
          href: `/${username}/articles`,
        },
        // {
        //   key: 'media',
        //   title: 'Media',
        //   href: `/${username}/media`,
        // },
        {
          key: 'draft',
          title: 'Draft',
          href: `/${username}/draft`,
        },
        {
          key: 'under-review',
          title: 'Under review',
          href: `/${username}/under-review`,
        },
        {
          key: 'declined',
          title: 'Declined',
          href: `/${username}/declined`,
        },
      ].filter(({ key }) => permissions.includes(key))}
      selectedKey={pathname.split('/')[2]}
    />
  );
};

export default UserProfileTab;
