import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/codebuild/output/src1072614492/src/bmw-head-frontend/node_modules/@nextui-org/avatar/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/codebuild/output/src1072614492/src/bmw-head-frontend/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1072614492/src/bmw-head-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1072614492/src/bmw-head-frontend/src/app/(main)/(user-profile)/[username]/actions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1072614492/src/bmw-head-frontend/src/app/(main)/(user-profile)/[username]/edit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1072614492/src/bmw-head-frontend/src/app/(main)/(user-profile)/[username]/follow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1072614492/src/bmw-head-frontend/src/app/(main)/(user-profile)/[username]/tab.tsx");
