'use client';

import { Button } from '@nextui-org/button';
import React from 'react';
import { useRouter } from 'next/navigation';
import { toast } from 'react-toastify';

import { useUserFollowMutation } from '@/hooks/mutations';

interface UserProfileActionProps {
  username: string;
  defaultState?: boolean;
}

const UserProfileAction: React.FC<UserProfileActionProps> = ({ username, defaultState }) => {
  const router = useRouter();
  const [isFollowing, setIsFollowing] = React.useState(defaultState);

  const { followMutation, unfollowMutation } = useUserFollowMutation();

  const handleToggleFollow = async () => {
    await (isFollowing ? unfollowMutation : followMutation).mutateAsync(username, {
      onSuccess: () => {
        router.refresh();
        setIsFollowing((prev) => !prev);
      },
      onError: () => {
        toast.error(`${isFollowing ? 'Unfollow' : 'Follow'} failed. Please try again later.`);
      },
    });
  };

  return (
    <>
      {isFollowing && (
        <Button
          className='border-gray-50'
          isLoading={unfollowMutation.isPending}
          radius='none'
          variant='bordered'
          onClick={handleToggleFollow}
        >
          Following
        </Button>
      )}

      {!isFollowing && (
        <Button
          color='primary'
          isLoading={followMutation.isPending}
          radius='none'
          variant='solid'
          onClick={handleToggleFollow}
        >
          Follow
        </Button>
      )}
    </>
  );
};

export default UserProfileAction;
