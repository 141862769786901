'use client';

import { Button } from '@nextui-org/button';
import { PencilSimpleLine } from '@phosphor-icons/react';
import React from 'react';
import { useRouter } from 'next/navigation';

import { EditProfileModal } from '@/modals';

interface EditUserProfileProps {
  username: string;
}

const EditUserProfile: React.FC<EditUserProfileProps> = ({ username }) => {
  const router = useRouter();
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = React.useState(false);

  return (
    <>
      <Button
        className='border-gray-50'
        endContent={<PencilSimpleLine size={20} />}
        radius='none'
        variant='bordered'
        onClick={() => setIsEditProfileModalOpen(true)}
      >
        Edit profile
      </Button>

      <EditProfileModal
        isOpen={isEditProfileModalOpen}
        username={username}
        onClose={() => {
          router.refresh();
          setIsEditProfileModalOpen(false);
        }}
      />
    </>
  );
};

export default EditUserProfile;
